import { useScript } from 'hooks/useScript';
import { useEffect } from 'react';

interface appleIDClientConfig {
  clientId: string;
  redirectURI: string;
  scope?: string;
  state?: string;
  nonce?: string;
  usePopup?: boolean;
}

interface appleIDAuthorization {
  code: string;
  id_token: string;
  state?: string;
}

interface appleIDUser {
  email: string;
  name: string;
}

interface appleIDSigninResponse {
  authorization: appleIDAuthorization;
  user?: appleIDUser;
}

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (config: appleIDClientConfig) => void;
        signIn: (
          config?: appleIDClientConfig
        ) => Promise<appleIDSigninResponse>;
      };
    };
  }
}

export function SignInWithAppleButton(props: {
  callback: (credential: string | undefined) => void;
}) {
  const [loaded, err] = useScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  );

  useEffect(() => {
    if (loaded === true && !err) {
      window.AppleID?.auth?.init({
        clientId: 'co.retrica.collageSignIn',
        redirectURI: process.env.REACT_APP_HOST,
        usePopup: true,
      });
    }
  }, [err, loaded]);

  const onClick = async (e: any = null) => {
    if (e) {
      e.preventDefault();
      try {
        const data = await window.AppleID.auth.signIn();
        const auth = data.authorization;
        props.callback(auth.id_token);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div
      id="appleid-signin"
      onClick={onClick}
      data-color="white"
      data-border="true"
      data-type="sign in"
      style={{ width: 200, height: 40 }}
    ></div>
  );
}
