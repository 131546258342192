// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=.ts"
// @generated from file retriver-enum.proto (package retrica, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum retrica.ErrorCode
 */
export enum ErrorCode {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: SUCCESS = 1;
   */
  SUCCESS = 1,

  /**
   * @generated from enum value: DEPRECATED_REQUEST = 6;
   */
  DEPRECATED_REQUEST = 6,

  /**
   * @generated from enum value: INVALID_PASSWORD = 7;
   */
  INVALID_PASSWORD = 7,

  /**
   * @generated from enum value: INVALID_USERNAME = 8;
   */
  INVALID_USERNAME = 8,

  /**
   * @generated from enum value: INVALID_EMAIL = 9;
   */
  INVALID_EMAIL = 9,

  /**
   * @generated from enum value: INVALID_PHONENUMBER = 10;
   */
  INVALID_PHONENUMBER = 10,

  /**
   * @generated from enum value: EMAIL_ALREADY_TAKEN = 11;
   */
  EMAIL_ALREADY_TAKEN = 11,

  /**
   * @generated from enum value: USERNAME_ALREADY_TAKEN = 12;
   */
  USERNAME_ALREADY_TAKEN = 12,

  /**
   * @generated from enum value: PASSWORD_MISMATCH = 13;
   */
  PASSWORD_MISMATCH = 13,

  /**
   * @generated from enum value: SMS_REQUEST_LIMIT = 14;
   */
  SMS_REQUEST_LIMIT = 14,

  /**
   * @generated from enum value: VERIFY_SMS_PASSCODE_MISMATCH = 15;
   */
  VERIFY_SMS_PASSCODE_MISMATCH = 15,

  /**
   * @generated from enum value: VERIFY_SMS_MANY_RETRY = 16;
   */
  VERIFY_SMS_MANY_RETRY = 16,

  /**
   * @generated from enum value: INVALID_AUTH_TOKEN = 17;
   */
  INVALID_AUTH_TOKEN = 17,

  /**
   * @generated from enum value: INVALID_ACCESS_TOKEN = 18;
   */
  INVALID_ACCESS_TOKEN = 18,

  /**
   * @generated from enum value: NOT_FOUND = 19;
   */
  NOT_FOUND = 19,

  /**
   * @generated from enum value: PERMISSION_DENIED = 20;
   */
  PERMISSION_DENIED = 20,

  /**
   * @generated from enum value: FACEBOOK_SIGNUP_REDIRECT = 21;
   */
  FACEBOOK_SIGNUP_REDIRECT = 21,

  /**
   * @generated from enum value: FACEBOOK_TOKEN_INVALID = 22;
   */
  FACEBOOK_TOKEN_INVALID = 22,

  /**
   * TODO: DEPRECATED
   *
   * @generated from enum value: FACEBOOK_LOGIN_NEED = 23;
   */
  FACEBOOK_LOGIN_NEED = 23,

  /**
   * @generated from enum value: FACEBOOK_ACCOUNT_MISMATCH = 24;
   */
  FACEBOOK_ACCOUNT_MISMATCH = 24,

  /**
   * @generated from enum value: VKONTAKTE_SIGNUP_REDIRECT = 25;
   */
  VKONTAKTE_SIGNUP_REDIRECT = 25,

  /**
   * @generated from enum value: VKONTAKTE_TOKEN_INVALID = 26;
   */
  VKONTAKTE_TOKEN_INVALID = 26,

  /**
   * @generated from enum value: VKONTAKTE_ACCOUNT_MISMATCH = 27;
   */
  VKONTAKTE_ACCOUNT_MISMATCH = 27,

  /**
   * @generated from enum value: APPLE_SIGNUP_REDIRECT = 34;
   */
  APPLE_SIGNUP_REDIRECT = 34,

  /**
   * @generated from enum value: APPLE_TOKEN_INVALID = 35;
   */
  APPLE_TOKEN_INVALID = 35,

  /**
   * @generated from enum value: APPLE_ACCOUNT_MISMATCH = 36;
   */
  APPLE_ACCOUNT_MISMATCH = 36,

  /**
   * @generated from enum value: HUAWEI_SIGN_IN_APP_ERROR = 37;
   */
  HUAWEI_SIGN_IN_APP_ERROR = 37,

  /**
   * @generated from enum value: HUAWEI_SIGN_IN_RETRIVER_ERROR = 38;
   */
  HUAWEI_SIGN_IN_RETRIVER_ERROR = 38,

  /**
   * @generated from enum value: HUAWEI_SIGNUP_REDIRECT = 39;
   */
  HUAWEI_SIGNUP_REDIRECT = 39,

  /**
   * @generated from enum value: SQUAD_LIMIT_OF_CREW = 28;
   */
  SQUAD_LIMIT_OF_CREW = 28,

  /**
   * @generated from enum value: SQUAD_LIMIT_OF_JOIN = 29;
   */
  SQUAD_LIMIT_OF_JOIN = 29,

  /**
   * @generated from enum value: SQUAD_PERMISSION_DENIED = 30;
   */
  SQUAD_PERMISSION_DENIED = 30,

  /**
   * @generated from enum value: CHAT_ALREADY_EXIST = 31;
   */
  CHAT_ALREADY_EXIST = 31,

  /**
   * @generated from enum value: INVALID_RECEIPT = 32;
   */
  INVALID_RECEIPT = 32,

  /**
   * @generated from enum value: EXPIRED = 33;
   */
  EXPIRED = 33,
}
// Retrieve enum metadata with: proto3.getEnumType(ErrorCode)
proto3.util.setEnumType(ErrorCode, "retrica.ErrorCode", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "SUCCESS" },
  { no: 6, name: "DEPRECATED_REQUEST" },
  { no: 7, name: "INVALID_PASSWORD" },
  { no: 8, name: "INVALID_USERNAME" },
  { no: 9, name: "INVALID_EMAIL" },
  { no: 10, name: "INVALID_PHONENUMBER" },
  { no: 11, name: "EMAIL_ALREADY_TAKEN" },
  { no: 12, name: "USERNAME_ALREADY_TAKEN" },
  { no: 13, name: "PASSWORD_MISMATCH" },
  { no: 14, name: "SMS_REQUEST_LIMIT" },
  { no: 15, name: "VERIFY_SMS_PASSCODE_MISMATCH" },
  { no: 16, name: "VERIFY_SMS_MANY_RETRY" },
  { no: 17, name: "INVALID_AUTH_TOKEN" },
  { no: 18, name: "INVALID_ACCESS_TOKEN" },
  { no: 19, name: "NOT_FOUND" },
  { no: 20, name: "PERMISSION_DENIED" },
  { no: 21, name: "FACEBOOK_SIGNUP_REDIRECT" },
  { no: 22, name: "FACEBOOK_TOKEN_INVALID" },
  { no: 23, name: "FACEBOOK_LOGIN_NEED" },
  { no: 24, name: "FACEBOOK_ACCOUNT_MISMATCH" },
  { no: 25, name: "VKONTAKTE_SIGNUP_REDIRECT" },
  { no: 26, name: "VKONTAKTE_TOKEN_INVALID" },
  { no: 27, name: "VKONTAKTE_ACCOUNT_MISMATCH" },
  { no: 34, name: "APPLE_SIGNUP_REDIRECT" },
  { no: 35, name: "APPLE_TOKEN_INVALID" },
  { no: 36, name: "APPLE_ACCOUNT_MISMATCH" },
  { no: 37, name: "HUAWEI_SIGN_IN_APP_ERROR" },
  { no: 38, name: "HUAWEI_SIGN_IN_RETRIVER_ERROR" },
  { no: 39, name: "HUAWEI_SIGNUP_REDIRECT" },
  { no: 28, name: "SQUAD_LIMIT_OF_CREW" },
  { no: 29, name: "SQUAD_LIMIT_OF_JOIN" },
  { no: 30, name: "SQUAD_PERMISSION_DENIED" },
  { no: 31, name: "CHAT_ALREADY_EXIST" },
  { no: 32, name: "INVALID_RECEIPT" },
  { no: 33, name: "EXPIRED" },
]);

/**
 * @generated from enum retrica.FriendType
 */
export enum FriendType {
  /**
   * 친구아님
   *
   * @generated from enum value: FT_NONE = 0;
   */
  FT_NONE = 0,

  /**
   * 친구
   *
   * @generated from enum value: FT_FRIEND = 1;
   */
  FT_FRIEND = 1,

  /**
   * 블럭
   *
   * @generated from enum value: FT_BLOCK = 2;
   */
  FT_BLOCK = 2,

  /**
   * 추천
   *
   * @generated from enum value: FT_RECOMMEND = 4;
   */
  FT_RECOMMEND = 4,

  /**
   * 나를 추가한 친구
   *
   * @generated from enum value: FT_ADDEDME = 8;
   */
  FT_ADDEDME = 8,

  /**
   * @generated from enum value: FT_FACEBOOK = 16;
   */
  FT_FACEBOOK = 16,

  /**
   * @generated from enum value: FT_VKONTAKTE = 32;
   */
  FT_VKONTAKTE = 32,

  /**
   * 나자신
   *
   * @generated from enum value: FT_MYSELF = 64;
   */
  FT_MYSELF = 64,
}
// Retrieve enum metadata with: proto3.getEnumType(FriendType)
proto3.util.setEnumType(FriendType, "retrica.FriendType", [
  { no: 0, name: "FT_NONE" },
  { no: 1, name: "FT_FRIEND" },
  { no: 2, name: "FT_BLOCK" },
  { no: 4, name: "FT_RECOMMEND" },
  { no: 8, name: "FT_ADDEDME" },
  { no: 16, name: "FT_FACEBOOK" },
  { no: 32, name: "FT_VKONTAKTE" },
  { no: 64, name: "FT_MYSELF" },
]);

/**
 * @generated from enum retrica.Toggle
 */
export enum Toggle {
  /**
   * @generated from enum value: TG_NONE = 0;
   */
  TG_NONE = 0,

  /**
   * @generated from enum value: TG_ON = 1;
   */
  TG_ON = 1,

  /**
   * @generated from enum value: TG_OFF = 2;
   */
  TG_OFF = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Toggle)
proto3.util.setEnumType(Toggle, "retrica.Toggle", [
  { no: 0, name: "TG_NONE" },
  { no: 1, name: "TG_ON" },
  { no: 2, name: "TG_OFF" },
]);

/**
 * @generated from enum retrica.ContentType
 */
export enum ContentType {
  /**
   * 없음
   *
   * @generated from enum value: COT_NONE = 0;
   */
  COT_NONE = 0,

  /**
   * 이미지
   *
   * @generated from enum value: COT_PHOTO = 1;
   */
  COT_PHOTO = 1,

  /**
   * 비디오
   *
   * @generated from enum value: COT_VIDEO = 2;
   */
  COT_VIDEO = 2,

  /**
   * 슬라이드 이미지(2x2)
   *
   * @generated from enum value: COT_SLIDE = 3;
   */
  COT_SLIDE = 3,

  /**
   * webp
   *
   * @generated from enum value: COT_WEBP = 4;
   */
  COT_WEBP = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentType)
proto3.util.setEnumType(ContentType, "retrica.ContentType", [
  { no: 0, name: "COT_NONE" },
  { no: 1, name: "COT_PHOTO" },
  { no: 2, name: "COT_VIDEO" },
  { no: 3, name: "COT_SLIDE" },
  { no: 4, name: "COT_WEBP" },
]);

/**
 * @generated from enum retrica.ResourceType
 */
export enum ResourceType {
  /**
   * @generated from enum value: RT_NONE = 0;
   */
  RT_NONE = 0,

  /**
   * @generated from enum value: RT_STICKER = 1;
   */
  RT_STICKER = 1,

  /**
   * @generated from enum value: RT_STAMP = 2;
   */
  RT_STAMP = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ResourceType)
proto3.util.setEnumType(ResourceType, "retrica.ResourceType", [
  { no: 0, name: "RT_NONE" },
  { no: 1, name: "RT_STICKER" },
  { no: 2, name: "RT_STAMP" },
]);

/**
 * @generated from enum retrica.ProductType
 */
export enum ProductType {
  /**
   * @generated from enum value: PTT_NONE = 0;
   */
  PTT_NONE = 0,

  /**
   * Pro 버전 구매 권유
   *
   * @generated from enum value: PTT_BUY_PRO = 1;
   */
  PTT_BUY_PRO = 1,

  /**
   * 필터팩 소개
   *
   * @generated from enum value: PTT_FILTERPACK = 2;
   */
  PTT_FILTERPACK = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductType)
proto3.util.setEnumType(ProductType, "retrica.ProductType", [
  { no: 0, name: "PTT_NONE" },
  { no: 1, name: "PTT_BUY_PRO" },
  { no: 2, name: "PTT_FILTERPACK" },
]);

/**
 * @generated from enum retrica.ProductPlan
 */
export enum ProductPlan {
  /**
   * @generated from enum value: PTP_NONE = 0;
   */
  PTP_NONE = 0,

  /**
   * 무료
   *
   * @generated from enum value: PTP_FREE = 1;
   */
  PTP_FREE = 1,

  /**
   * Pro 버전
   *
   * @generated from enum value: PTP_PRO = 2;
   */
  PTP_PRO = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductPlan)
proto3.util.setEnumType(ProductPlan, "retrica.ProductPlan", [
  { no: 0, name: "PTP_NONE" },
  { no: 1, name: "PTP_FREE" },
  { no: 2, name: "PTP_PRO" },
]);

/**
 * @generated from enum retrica.ThemeType
 */
export enum ThemeType {
  /**
   * @generated from enum value: TT_NONE = 0;
   */
  TT_NONE = 0,

  /**
   * @generated from enum value: TT_WHITE = 1;
   */
  TT_WHITE = 1,

  /**
   * @generated from enum value: TT_ORANGE = 2;
   */
  TT_ORANGE = 2,

  /**
   * @generated from enum value: TT_YELLOW = 3;
   */
  TT_YELLOW = 3,

  /**
   * @generated from enum value: TT_MINT = 4;
   */
  TT_MINT = 4,

  /**
   * @generated from enum value: TT_PURPLE = 5;
   */
  TT_PURPLE = 5,

  /**
   * @generated from enum value: TT_MAGENTA = 6;
   */
  TT_MAGENTA = 6,

  /**
   * @generated from enum value: TT_PASTELBLUE = 7;
   */
  TT_PASTELBLUE = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(ThemeType)
proto3.util.setEnumType(ThemeType, "retrica.ThemeType", [
  { no: 0, name: "TT_NONE" },
  { no: 1, name: "TT_WHITE" },
  { no: 2, name: "TT_ORANGE" },
  { no: 3, name: "TT_YELLOW" },
  { no: 4, name: "TT_MINT" },
  { no: 5, name: "TT_PURPLE" },
  { no: 6, name: "TT_MAGENTA" },
  { no: 7, name: "TT_PASTELBLUE" },
]);

/**
 * @generated from enum retrica.ReportType
 */
export enum ReportType {
  /**
   * @generated from enum value: RET_NONE = 0;
   */
  RET_NONE = 0,

  /**
   * @generated from enum value: RET_ANNOYING = 1;
   */
  RET_ANNOYING = 1,

  /**
   * @generated from enum value: RET_SEX = 2;
   */
  RET_SEX = 2,

  /**
   * @generated from enum value: RET_SPAM = 3;
   */
  RET_SPAM = 3,

  /**
   * @generated from enum value: RET_ETC = 4;
   */
  RET_ETC = 4,

  /**
   * @generated from enum value: RET_ADMIN = 5;
   */
  RET_ADMIN = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportType)
proto3.util.setEnumType(ReportType, "retrica.ReportType", [
  { no: 0, name: "RET_NONE" },
  { no: 1, name: "RET_ANNOYING" },
  { no: 2, name: "RET_SEX" },
  { no: 3, name: "RET_SPAM" },
  { no: 4, name: "RET_ETC" },
  { no: 5, name: "RET_ADMIN" },
]);

/**
 * [gaia]
 *
 * @generated from enum retrica.AlertType
 */
export enum AlertType {
  /**
   * @generated from enum value: ALT_NONE = 0;
   */
  ALT_NONE = 0,

  /**
   * @generated from enum value: ALT_OFFICAL = 1;
   */
  ALT_OFFICAL = 1,

  /**
   * @generated from enum value: ALT_UPLOAD = 2;
   */
  ALT_UPLOAD = 2,

  /**
   * @generated from enum value: ALT_COMMENT = 3;
   */
  ALT_COMMENT = 3,

  /**
   * @generated from enum value: ALT_MENTION = 4;
   */
  ALT_MENTION = 4,

  /**
   * @generated from enum value: ALT_LIKE = 5;
   */
  ALT_LIKE = 5,

  /**
   * @generated from enum value: ALT_FOLLOWING = 6;
   */
  ALT_FOLLOWING = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(AlertType)
proto3.util.setEnumType(AlertType, "retrica.AlertType", [
  { no: 0, name: "ALT_NONE" },
  { no: 1, name: "ALT_OFFICAL" },
  { no: 2, name: "ALT_UPLOAD" },
  { no: 3, name: "ALT_COMMENT" },
  { no: 4, name: "ALT_MENTION" },
  { no: 5, name: "ALT_LIKE" },
  { no: 6, name: "ALT_FOLLOWING" },
]);

/**
 * @generated from enum retrica.SnsType
 */
export enum SnsType {
  /**
   * @generated from enum value: SST_NONE = 0;
   */
  SST_NONE = 0,

  /**
   * @generated from enum value: SST_FACEBOOK = 1;
   */
  SST_FACEBOOK = 1,

  /**
   * @generated from enum value: SST_VKONTAKTE = 2;
   */
  SST_VKONTAKTE = 2,

  /**
   * @generated from enum value: SST_APPLE = 3;
   */
  SST_APPLE = 3,

  /**
   * @generated from enum value: SST_HUAWEI = 4;
   */
  SST_HUAWEI = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(SnsType)
proto3.util.setEnumType(SnsType, "retrica.SnsType", [
  { no: 0, name: "SST_NONE" },
  { no: 1, name: "SST_FACEBOOK" },
  { no: 2, name: "SST_VKONTAKTE" },
  { no: 3, name: "SST_APPLE" },
  { no: 4, name: "SST_HUAWEI" },
]);

/**
 * 레트리카 계정을 다른app 이용시 로그인 ..등 분리하기 위한 목적
 *
 * @generated from enum retrica.ProjectType
 */
export enum ProjectType {
  /**
   * @generated from enum value: PT_NONE = 0;
   */
  PT_NONE = 0,

  /**
   * @generated from enum value: PT_RETRICA = 1;
   */
  PT_RETRICA = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ProjectType)
proto3.util.setEnumType(ProjectType, "retrica.ProjectType", [
  { no: 0, name: "PT_NONE" },
  { no: 1, name: "PT_RETRICA" },
]);

/**
 * @generated from enum retrica.FeedItemType
 */
export enum FeedItemType {
  /**
   * @generated from enum value: FI_TYPE_SHOT = 0;
   */
  FI_TYPE_SHOT = 0,

  /**
   * @generated from enum value: FI_TYPE_PRODUCT = 1;
   */
  FI_TYPE_PRODUCT = 1,

  /**
   * @generated from enum value: FI_TYPE_QUOTE = 2;
   */
  FI_TYPE_QUOTE = 2,

  /**
   * @generated from enum value: FI_TYPE_LINK = 3;
   */
  FI_TYPE_LINK = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(FeedItemType)
proto3.util.setEnumType(FeedItemType, "retrica.FeedItemType", [
  { no: 0, name: "FI_TYPE_SHOT" },
  { no: 1, name: "FI_TYPE_PRODUCT" },
  { no: 2, name: "FI_TYPE_QUOTE" },
  { no: 3, name: "FI_TYPE_LINK" },
]);

/**
 * @generated from enum retrica.NotificationType
 */
export enum NotificationType {
  /**
   * @generated from enum value: NT_NONE = 0;
   */
  NT_NONE = 0,

  /**
   * 업로드 이미지
   *
   * @generated from enum value: NT_UPLOAD_IMAGE_CONTENT = 1;
   */
  NT_UPLOAD_IMAGE_CONTENT = 1,

  /**
   * 업로드 비디오
   *
   * @generated from enum value: NT_UPLOAD_VIDEO_CONTENT = 2;
   */
  NT_UPLOAD_VIDEO_CONTENT = 2,

  /**
   * 이미지에 댓글
   *
   * @generated from enum value: NT_WRITE_IMAGE_COMMENT = 3;
   */
  NT_WRITE_IMAGE_COMMENT = 3,

  /**
   * 비디오에 댓글
   *
   * @generated from enum value: NT_WRITE_VIDEO_COMMENT = 4;
   */
  NT_WRITE_VIDEO_COMMENT = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(NotificationType)
proto3.util.setEnumType(NotificationType, "retrica.NotificationType", [
  { no: 0, name: "NT_NONE" },
  { no: 1, name: "NT_UPLOAD_IMAGE_CONTENT" },
  { no: 2, name: "NT_UPLOAD_VIDEO_CONTENT" },
  { no: 3, name: "NT_WRITE_IMAGE_COMMENT" },
  { no: 4, name: "NT_WRITE_VIDEO_COMMENT" },
]);

/**
 * @generated from enum retrica.ChannelContentType
 */
export enum ChannelContentType {
  /**
   * 없음
   *
   * @generated from enum value: CCT_NONE = 0;
   */
  CCT_NONE = 0,

  /**
   * 이미지
   *
   * @generated from enum value: CCT_IMAGE = 1;
   */
  CCT_IMAGE = 1,

  /**
   * 비디오
   *
   * @generated from enum value: CCT_VIDEO = 2;
   */
  CCT_VIDEO = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ChannelContentType)
proto3.util.setEnumType(ChannelContentType, "retrica.ChannelContentType", [
  { no: 0, name: "CCT_NONE" },
  { no: 1, name: "CCT_IMAGE" },
  { no: 2, name: "CCT_VIDEO" },
]);

/**
 * @generated from enum retrica.ChannelType
 */
export enum ChannelType {
  /**
   * 없음
   *
   * @generated from enum value: CT_NONE = 0;
   */
  CT_NONE = 0,

  /**
   * 1:1
   *
   * @generated from enum value: CT_PRIVATE = 1;
   */
  CT_PRIVATE = 1,

  /**
   * 그룹
   *
   * @generated from enum value: CT_GROUP = 2;
   */
  CT_GROUP = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ChannelType)
proto3.util.setEnumType(ChannelType, "retrica.ChannelType", [
  { no: 0, name: "CT_NONE" },
  { no: 1, name: "CT_PRIVATE" },
  { no: 2, name: "CT_GROUP" },
]);

/**
 * @generated from enum retrica.SettingType
 */
export enum SettingType {
  /**
   * 없음
   *
   * @generated from enum value: ST_NONE = 0;
   */
  ST_NONE = 0,

  /**
   * OFF
   *
   * @generated from enum value: ST_OFF = 1;
   */
  ST_OFF = 1,

  /**
   * ON
   *
   * @generated from enum value: ST_ON = 2;
   */
  ST_ON = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SettingType)
proto3.util.setEnumType(SettingType, "retrica.SettingType", [
  { no: 0, name: "ST_NONE" },
  { no: 1, name: "ST_OFF" },
  { no: 2, name: "ST_ON" },
]);

/**
 * @generated from enum retrica.HashTagType
 */
export enum HashTagType {
  /**
   * @generated from enum value: HTT_NONE = 0;
   */
  HTT_NONE = 0,

  /**
   * @generated from enum value: HTT_USER_TAG = 1;
   */
  HTT_USER_TAG = 1,

  /**
   * @generated from enum value: HTT_RETRICA_TAG = 2;
   */
  HTT_RETRICA_TAG = 2,

  /**
   * @generated from enum value: HTT_LOCATION_TAG = 3;
   */
  HTT_LOCATION_TAG = 3,

  /**
   * @generated from enum value: HTT_VIRTUAL_TAG = 4;
   */
  HTT_VIRTUAL_TAG = 4,

  /**
   * @generated from enum value: HTT_HIDDEN_TAG = 5;
   */
  HTT_HIDDEN_TAG = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(HashTagType)
proto3.util.setEnumType(HashTagType, "retrica.HashTagType", [
  { no: 0, name: "HTT_NONE" },
  { no: 1, name: "HTT_USER_TAG" },
  { no: 2, name: "HTT_RETRICA_TAG" },
  { no: 3, name: "HTT_LOCATION_TAG" },
  { no: 4, name: "HTT_VIRTUAL_TAG" },
  { no: 5, name: "HTT_HIDDEN_TAG" },
]);

/**
 * @generated from enum retrica.RankType
 */
export enum RankType {
  /**
   * @generated from enum value: RANK_NONE = 0;
   */
  RANK_NONE = 0,

  /**
   * @generated from enum value: RANK_VIEW = 1;
   */
  RANK_VIEW = 1,

  /**
   * @generated from enum value: RANK_LIKE = 2;
   */
  RANK_LIKE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(RankType)
proto3.util.setEnumType(RankType, "retrica.RankType", [
  { no: 0, name: "RANK_NONE" },
  { no: 1, name: "RANK_VIEW" },
  { no: 2, name: "RANK_LIKE" },
]);

/**
 * @generated from enum retrica.ActivityType
 */
export enum ActivityType {
  /**
   * @generated from enum value: AT_NONE = 0;
   */
  AT_NONE = 0,

  /**
   * @generated from enum value: AT_ADDEDME = 1;
   */
  AT_ADDEDME = 1,

  /**
   * @generated from enum value: AT_UPLOAD_SELFIE = 2;
   */
  AT_UPLOAD_SELFIE = 2,

  /**
   * @generated from enum value: AT_VIEW_SELFIE = 3;
   */
  AT_VIEW_SELFIE = 3,

  /**
   * @generated from enum value: AT_LIKE_SELFIE = 4;
   */
  AT_LIKE_SELFIE = 4,

  /**
   * @generated from enum value: AT_MENTION = 5;
   */
  AT_MENTION = 5,

  /**
   * @generated from enum value: AT_JOIN_SQUAD = 6;
   */
  AT_JOIN_SQUAD = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ActivityType)
proto3.util.setEnumType(ActivityType, "retrica.ActivityType", [
  { no: 0, name: "AT_NONE" },
  { no: 1, name: "AT_ADDEDME" },
  { no: 2, name: "AT_UPLOAD_SELFIE" },
  { no: 3, name: "AT_VIEW_SELFIE" },
  { no: 4, name: "AT_LIKE_SELFIE" },
  { no: 5, name: "AT_MENTION" },
  { no: 6, name: "AT_JOIN_SQUAD" },
]);

/**
 * @generated from enum retrica.TimelineType
 */
export enum TimelineType {
  /**
   * @generated from enum value: TLT_NONE = 0;
   */
  TLT_NONE = 0,

  /**
   * @generated from enum value: TLT_SELFIE = 1;
   */
  TLT_SELFIE = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(TimelineType)
proto3.util.setEnumType(TimelineType, "retrica.TimelineType", [
  { no: 0, name: "TLT_NONE" },
  { no: 1, name: "TLT_SELFIE" },
]);

/**
 * @generated from enum retrica.SquadModeType
 */
export enum SquadModeType {
  /**
   * @generated from enum value: SMT_NONE = 0;
   */
  SMT_NONE = 0,

  /**
   * @generated from enum value: SMT_JOIN = 1;
   */
  SMT_JOIN = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SquadModeType)
proto3.util.setEnumType(SquadModeType, "retrica.SquadModeType", [
  { no: 0, name: "SMT_NONE" },
  { no: 1, name: "SMT_JOIN" },
]);

/**
 * @generated from enum retrica.UnreadType
 */
export enum UnreadType {
  /**
   * @generated from enum value: UT_NONE = 0;
   */
  UT_NONE = 0,

  /**
   * @generated from enum value: UT_CONTENT = 1;
   */
  UT_CONTENT = 1,

  /**
   * @generated from enum value: UT_ADDED_ME = 2;
   */
  UT_ADDED_ME = 2,

  /**
   * @generated from enum value: UT_ACTIVITY = 3;
   */
  UT_ACTIVITY = 3,

  /**
   * @generated from enum value: UT_TIMELINE = 4;
   */
  UT_TIMELINE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(UnreadType)
proto3.util.setEnumType(UnreadType, "retrica.UnreadType", [
  { no: 0, name: "UT_NONE" },
  { no: 1, name: "UT_CONTENT" },
  { no: 2, name: "UT_ADDED_ME" },
  { no: 3, name: "UT_ACTIVITY" },
  { no: 4, name: "UT_TIMELINE" },
]);

/**
 * @generated from enum retrica.FcmType
 */
export enum FcmType {
  /**
   * @generated from enum value: FCMT_NONE = 0;
   */
  FCMT_NONE = 0,

  /**
   * @generated from enum value: FCMT_UPLOAD_IMAGE_CONTENT = 1;
   */
  FCMT_UPLOAD_IMAGE_CONTENT = 1,

  /**
   * @generated from enum value: FCMT_UPLOAD_VIDEO_CONTENT = 2;
   */
  FCMT_UPLOAD_VIDEO_CONTENT = 2,

  /**
   * @generated from enum value: FCMT_WRITE_IMAGE_COMMENT = 3;
   */
  FCMT_WRITE_IMAGE_COMMENT = 3,

  /**
   * @generated from enum value: FCMT_WRITE_VIDEO_COMMENT = 4;
   */
  FCMT_WRITE_VIDEO_COMMENT = 4,

  /**
   * @generated from enum value: FCMT_ADDEDME = 5;
   */
  FCMT_ADDEDME = 5,

  /**
   * @generated from enum value: FCMT_NEW_GROUP = 6;
   */
  FCMT_NEW_GROUP = 6,

  /**
   * @generated from enum value: FCMT_ADD_GROUP_MEMBERS = 7;
   */
  FCMT_ADD_GROUP_MEMBERS = 7,

  /**
   * @generated from enum value: FCMT_LIKE_SELFIE = 8;
   */
  FCMT_LIKE_SELFIE = 8,

  /**
   * @generated from enum value: FCMT_UPLOAD_SELFIE = 9;
   */
  FCMT_UPLOAD_SELFIE = 9,

  /**
   * @generated from enum value: FCMT_MENTION_SELFIE = 10;
   */
  FCMT_MENTION_SELFIE = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(FcmType)
proto3.util.setEnumType(FcmType, "retrica.FcmType", [
  { no: 0, name: "FCMT_NONE" },
  { no: 1, name: "FCMT_UPLOAD_IMAGE_CONTENT" },
  { no: 2, name: "FCMT_UPLOAD_VIDEO_CONTENT" },
  { no: 3, name: "FCMT_WRITE_IMAGE_COMMENT" },
  { no: 4, name: "FCMT_WRITE_VIDEO_COMMENT" },
  { no: 5, name: "FCMT_ADDEDME" },
  { no: 6, name: "FCMT_NEW_GROUP" },
  { no: 7, name: "FCMT_ADD_GROUP_MEMBERS" },
  { no: 8, name: "FCMT_LIKE_SELFIE" },
  { no: 9, name: "FCMT_UPLOAD_SELFIE" },
  { no: 10, name: "FCMT_MENTION_SELFIE" },
]);

/**
 * @generated from enum retrica.BlendType
 */
export enum BlendType {
  /**
   * default
   *
   * @generated from enum value: ALPHA = 0;
   */
  ALPHA = 0,

  /**
   * @generated from enum value: MULTIPLY = 1;
   */
  MULTIPLY = 1,

  /**
   * @generated from enum value: COLOR_BURN = 2;
   */
  COLOR_BURN = 2,

  /**
   * @generated from enum value: LIGHTEN = 3;
   */
  LIGHTEN = 3,

  /**
   * @generated from enum value: SCREEN = 4;
   */
  SCREEN = 4,

  /**
   * @generated from enum value: OVERLAY = 5;
   */
  OVERLAY = 5,

  /**
   * @generated from enum value: SOFT_LIGHT = 6;
   */
  SOFT_LIGHT = 6,

  /**
   * @generated from enum value: HARD_LIGHT = 7;
   */
  HARD_LIGHT = 7,

  /**
   * @generated from enum value: DIFFERENCE = 8;
   */
  DIFFERENCE = 8,

  /**
   * @generated from enum value: DARKEN = 9;
   */
  DARKEN = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(BlendType)
proto3.util.setEnumType(BlendType, "retrica.BlendType", [
  { no: 0, name: "ALPHA" },
  { no: 1, name: "MULTIPLY" },
  { no: 2, name: "COLOR_BURN" },
  { no: 3, name: "LIGHTEN" },
  { no: 4, name: "SCREEN" },
  { no: 5, name: "OVERLAY" },
  { no: 6, name: "SOFT_LIGHT" },
  { no: 7, name: "HARD_LIGHT" },
  { no: 8, name: "DIFFERENCE" },
  { no: 9, name: "DARKEN" },
]);

/**
 * @generated from enum retrica.Alignment
 */
export enum Alignment {
  /**
   * default
   *
   * @generated from enum value: BOTTOM_RIGHT = 0;
   */
  BOTTOM_RIGHT = 0,

  /**
   * @generated from enum value: BOTTOM = 1;
   */
  BOTTOM = 1,

  /**
   * @generated from enum value: BOTTOM_LEFT = 2;
   */
  BOTTOM_LEFT = 2,

  /**
   * @generated from enum value: RIGHT = 3;
   */
  RIGHT = 3,

  /**
   * @generated from enum value: CENTER = 4;
   */
  CENTER = 4,

  /**
   * @generated from enum value: LEFT = 5;
   */
  LEFT = 5,

  /**
   * @generated from enum value: TOP_RIGHT = 6;
   */
  TOP_RIGHT = 6,

  /**
   * @generated from enum value: TOP = 7;
   */
  TOP = 7,

  /**
   * @generated from enum value: TOP_LEFT = 8;
   */
  TOP_LEFT = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(Alignment)
proto3.util.setEnumType(Alignment, "retrica.Alignment", [
  { no: 0, name: "BOTTOM_RIGHT" },
  { no: 1, name: "BOTTOM" },
  { no: 2, name: "BOTTOM_LEFT" },
  { no: 3, name: "RIGHT" },
  { no: 4, name: "CENTER" },
  { no: 5, name: "LEFT" },
  { no: 6, name: "TOP_RIGHT" },
  { no: 7, name: "TOP" },
  { no: 8, name: "TOP_LEFT" },
]);

/**
 * @generated from enum retrica.DateTimeFormat
 */
export enum DateTimeFormat {
  /**
   * @generated from enum value: MEDIUM_DATE_TIME = 0;
   */
  MEDIUM_DATE_TIME = 0,

  /**
   * @generated from enum value: MEDIUM_DATE = 1;
   */
  MEDIUM_DATE = 1,

  /**
   * @generated from enum value: MEDIUM_TIME = 2;
   */
  MEDIUM_TIME = 2,

  /**
   * @generated from enum value: SHORT_DATE_TIME = 3;
   */
  SHORT_DATE_TIME = 3,

  /**
   * @generated from enum value: SHORT_DATE = 4;
   */
  SHORT_DATE = 4,

  /**
   * @generated from enum value: SHORT_TIME = 5;
   */
  SHORT_TIME = 5,

  /**
   * @generated from enum value: LONG_DATE_TIME = 6;
   */
  LONG_DATE_TIME = 6,

  /**
   * @generated from enum value: LONG_DATE = 7;
   */
  LONG_DATE = 7,

  /**
   * @generated from enum value: LONG_TIME = 8;
   */
  LONG_TIME = 8,

  /**
   * @generated from enum value: FULL_DATE_TIME = 9;
   */
  FULL_DATE_TIME = 9,

  /**
   * @generated from enum value: FULL_DATE = 10;
   */
  FULL_DATE = 10,

  /**
   * @generated from enum value: FULL_TIME = 11;
   */
  FULL_TIME = 11,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_1 = 12;
   */
  RETRICA_DTFORMAT_1 = 12,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_2 = 13;
   */
  RETRICA_DTFORMAT_2 = 13,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_3 = 14;
   */
  RETRICA_DTFORMAT_3 = 14,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_4 = 15;
   */
  RETRICA_DTFORMAT_4 = 15,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_5 = 16;
   */
  RETRICA_DTFORMAT_5 = 16,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_6 = 17;
   */
  RETRICA_DTFORMAT_6 = 17,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_7 = 18;
   */
  RETRICA_DTFORMAT_7 = 18,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_8 = 19;
   */
  RETRICA_DTFORMAT_8 = 19,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_9 = 20;
   */
  RETRICA_DTFORMAT_9 = 20,

  /**
   * @generated from enum value: RETRICA_DTFORMAT_10 = 21;
   */
  RETRICA_DTFORMAT_10 = 21,
}
// Retrieve enum metadata with: proto3.getEnumType(DateTimeFormat)
proto3.util.setEnumType(DateTimeFormat, "retrica.DateTimeFormat", [
  { no: 0, name: "MEDIUM_DATE_TIME" },
  { no: 1, name: "MEDIUM_DATE" },
  { no: 2, name: "MEDIUM_TIME" },
  { no: 3, name: "SHORT_DATE_TIME" },
  { no: 4, name: "SHORT_DATE" },
  { no: 5, name: "SHORT_TIME" },
  { no: 6, name: "LONG_DATE_TIME" },
  { no: 7, name: "LONG_DATE" },
  { no: 8, name: "LONG_TIME" },
  { no: 9, name: "FULL_DATE_TIME" },
  { no: 10, name: "FULL_DATE" },
  { no: 11, name: "FULL_TIME" },
  { no: 12, name: "RETRICA_DTFORMAT_1" },
  { no: 13, name: "RETRICA_DTFORMAT_2" },
  { no: 14, name: "RETRICA_DTFORMAT_3" },
  { no: 15, name: "RETRICA_DTFORMAT_4" },
  { no: 16, name: "RETRICA_DTFORMAT_5" },
  { no: 17, name: "RETRICA_DTFORMAT_6" },
  { no: 18, name: "RETRICA_DTFORMAT_7" },
  { no: 19, name: "RETRICA_DTFORMAT_8" },
  { no: 20, name: "RETRICA_DTFORMAT_9" },
  { no: 21, name: "RETRICA_DTFORMAT_10" },
]);

/**
 * @generated from enum retrica.UserRole
 */
export enum UserRole {
  /**
   * @generated from enum value: ROLE_NONE = 0;
   */
  ROLE_NONE = 0,

  /**
   * @generated from enum value: ROLE_ADMIN = 1;
   */
  ROLE_ADMIN = 1,

  /**
   * @generated from enum value: ROLE_CREATOR = 2;
   */
  ROLE_CREATOR = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UserRole)
proto3.util.setEnumType(UserRole, "retrica.UserRole", [
  { no: 0, name: "ROLE_NONE" },
  { no: 1, name: "ROLE_ADMIN" },
  { no: 2, name: "ROLE_CREATOR" },
]);

