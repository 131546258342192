import { useRef, useState } from 'react';
import { Preset } from 'types/proto/retriver-struct_pb';
import { SearchQuery, useFetchPresets } from 'hooks/useFetchPresets';
import { PresetCompactView } from './components/PresetCompactView';
import viewStyle from 'styles/view.module.css';
import { SearchForm } from './components/SearchForm';
import React from 'react';
import { SubTitle } from 'components/PageTitles';

export function Search() {
  const lastItemRef = useRef(null);
  const [query, setQuery] = useState<SearchQuery>({});
  const [list, setList, hasMore] = useFetchPresets({
    showHidden: true,
    query: query,
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Search</SubTitle>
      <SearchForm
        onSearch={(values: any) => {
          setQuery({
            title: values.title,
            description: values.description,
            tags: values.tags,
          });
        }}
      />
      <div className={`${viewStyle.viewbox} ${viewStyle.wrap}`}>
        {list.map((p: Preset, i: number) => {
          return (
            <PresetCompactView
              key={p.id}
              preset={p}
              onDelete={(id: string) => {
                setList(list.filter((p) => p.id !== id));
              }}
              onUpdate={(updated: Preset) => {
                setList((prev) => {
                  const cur = prev.slice();
                  cur.splice(i, 1, updated);
                  return cur;
                });
              }}
            />
          );
        })}
        {hasMore && (
          <div
            key="presetListLastItemRef"
            ref={lastItemRef}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem',
              minHeight: '100px',
            }}
          ></div>
        )}
      </div>
    </React.Fragment>
  );
}
