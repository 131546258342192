import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import viewStyle from 'styles/view.module.css';
import { Popover, Typography } from 'antd';
import { IdPopover } from 'components/IdPopover';
import RawJson from 'utils/RawJson';
import { ExpandOutlined } from '@ant-design/icons';
import { PresetFullView, PresetViewProps } from './PresetFullView';

export function PresetCompactView(props: PresetViewProps) {
  return (
    <div className={viewStyle.viewitem}>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'center',
          width: '220px',
        }}
      >
        <Typography.Title
          level={5}
          style={{ marginTop: '0.5rem', marginRight: '1rem' }}
        >
          {props.preset.metadata?.title}
        </Typography.Title>
        <Popover
          content={
            <PresetFullView
              preset={props.preset}
              onDelete={props.onDelete}
              onUpdate={props.onUpdate}
            />
          }
          trigger="click"
        >
          <Typography.Link>
            <ExpandOutlined />
          </Typography.Link>
        </Popover>
      </div>
      <ThumbnailView
        size={ThumbnailSizes.full}
        src={props.preset.metadata?.previewUrl ?? ''}
        style={{ marginBottom: '0.4rem' }}
      />
      <IdPopover id={props.preset.id} content={RawJson(props.preset)} />
    </div>
  );
}
