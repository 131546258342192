import {
  DeletePresetRequest,
  GetPresetRecommendationRequest,
  GetPresetsRequest,
  HidePresetRequest,
  SearchPresetsRequest,
  UpdatePresetRecommendationRequest,
  UpdatePresetRequest,
} from 'types/proto/retriver-app-common_pb';
import { RequestProto } from 'types/proto/retriver_pb';
import { ApiRequest } from '../retriver';

export function NewGetPresetRequest(id: string): ApiRequest {
  return {
    path: 'h1/ac/getPreset',
    protoBody: new RequestProto({
      getPresetsRequest: new GetPresetsRequest({
        ids: [id],
      }),
    }),
  };
}

export function NewSearchPresetRequest(
  offset: string,
  size: number,
  showHidden: boolean,
  title?: string,
  description?: string,
  tags?: string,
  rawTag?: string
): ApiRequest {
  return {
    path: 'h1/ac/searchPreset',
    protoBody: new RequestProto({
      searchPresetsRequest: new SearchPresetsRequest({
        offset: offset,
        size: size,
        showHidden: showHidden,
        title: title,
        description: description,
        tags: tags,
        rawTag: rawTag,
      }),
    }),
  };
}

export function NewHidePresetRequest(id: string, hide: boolean): ApiRequest {
  return {
    path: 'a2/ac/hidePreset',
    protoBody: new RequestProto({
      hidePresetRequest: new HidePresetRequest({
        id: id,
        hide: hide,
      }),
    }),
  };
}

export function NewDeletePresetRequest(id: string): ApiRequest {
  return {
    path: 'a2/ac/deletePreset',
    protoBody: new RequestProto({
      deletePresetRequest: new DeletePresetRequest({
        id: id,
      }),
    }),
  };
}

export function NewUpdatePresetRequest(
  id: string,
  props: {
    previewUrl?: string;
    originUrl?: string;
    tags?: string[];
    title?: string;
    description?: string;
  }
): ApiRequest {
  return {
    path: 'a2/ac/updatePreset',
    protoBody: new RequestProto({
      updatePresetRequest: new UpdatePresetRequest({
        id: id,
        previewUrl: props.previewUrl,
        originUrl: props.originUrl,
        tags: props.tags,
        title: props.title,
        description: props.description,
      }),
    }),
  };
}

export function NewGetPresetRecommendationRequest(): ApiRequest {
  return {
    path: 'h1/ac/getPresetRecommendation',
    protoBody: new RequestProto({
      getPresetRecommendationRequest: new GetPresetRecommendationRequest(),
    }),
  };
}

export function NewUpdatePresetRecommendationRequest(
  tags?: string[]
): ApiRequest {
  return {
    path: 'a2/ac/updatePresetRecommendation',
    protoBody: new RequestProto({
      updatePresetRecommendationRequest: new UpdatePresetRecommendationRequest({
        tags: tags,
      }),
    }),
  };
}
