import {
  CameraConfiguration,
  CameraConfiguration_CameraRatio,
  CameraConfiguration_DeviceType,
  CameraConfiguration_Flash,
  CameraConfiguration_Position,
  Preset,
} from 'types/proto/retriver-struct_pb';
import { ImageComparisonView } from '../../../../components/Thumbnail/ImageComparisonView';
import { FallbackImage } from 'utils/constants';
import {
  Button,
  Descriptions,
  Divider,
  Form,
  Modal,
  Popconfirm,
  Spin,
  Typography,
  Watermark,
} from 'antd';
import viewStyle from 'styles/view.module.css';
import {
  CameraOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FileImageOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { proto3 } from '@bufbuild/protobuf';
import { ImageProcessingView } from './ImageProcessingView';
import RawJson from 'utils/RawJson';
import { IdPopover } from 'components/IdPopover';
import { Retriver } from 'api/retriver/retriver';
import { useEffect, useRef, useState } from 'react';
import { ErrorCode } from 'types/proto/retriver-enum_pb';
import {
  NewDeletePresetRequest,
  NewHidePresetRequest,
  NewUpdatePresetRequest,
} from 'api/retriver/requestFactory/presets';
import { getMediaResolution } from 'utils/media';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import { acceptTypesInfo, FileUploader } from 'components/FileUploader';
import { PresetImageAcceptTypes } from 'types/material';
import { ulid } from 'ulid';
import { TagEntries } from 'components/TagEntries';

export interface PresetViewProps {
  preset: Preset;
  onDelete?: (id: string) => void;
  onUpdate?: (p: Preset) => void;
}

export function PresetFullView(props: PresetViewProps) {
  const [preset, setPreset] = useState<Preset>(props.preset);
  const [toggleHiddenLoading, setToggleHiddenLoading] =
    useState<boolean>(false);
  const [deletePresetLoading, setDeletePresetLoading] =
    useState<boolean>(false);
  const imgWidth = 300;
  const boxId = `box-${preset.id}`;

  const [modalForm] = useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const title = useRef<string>(props.preset.metadata?.title ?? '');
  const description = useRef<string>(props.preset.metadata?.description ?? '');
  const [titleUpdating, setTitleUpdating] = useState<boolean>(false);
  const [descriptionUpdating, setDescriptionUpdating] =
    useState<boolean>(false);

  let newPreviewFilename = useRef('');
  let newOriginFilename = useRef('');
  let oldTitle: string;
  let oldDescription: string;

  const toggleHidden = async () => {
    setToggleHiddenLoading(true);
    const result = (
      await Retriver.do(
        NewHidePresetRequest(preset.id, !preset.metadata?.hidden)
      )
    )?.hidePresetResponse?.preset;
    setToggleHiddenLoading(false);
    if (result) {
      setPreset(result);
    }
  };

  const deletePreset = async () => {
    setDeletePresetLoading(true);
    const result = (await Retriver.do(NewDeletePresetRequest(preset.id)))
      ?.deletePresetResponse;
    if (result && result.errorCode === ErrorCode.SUCCESS) {
      props.onDelete?.(preset.id);
    }
    setDeletePresetLoading(false);
  };

  const handleModalFormSubmit = async (values: any) => {
    setModalOpen(false);
    modalForm.resetFields();
    if (!values.preview && !values.origin) {
      return;
    }
    const result = await Retriver.do(
      NewUpdatePresetRequest(preset.id, {
        previewUrl: values.preview?.[0].url,
        originUrl: values.origin?.[0].url,
        tags: preset.metadata?.tags,
      })
    );
    if (result?.updatePresetResponse?.preset) {
      setPreset(result.updatePresetResponse.preset);
    }
  };

  const updateTags = async (value: string[]) => {
    const result = await Retriver.do(
      NewUpdatePresetRequest(preset.id, {
        tags: value,
      })
    );
    if (result?.updatePresetResponse?.preset) {
      setPreset(result.updatePresetResponse.preset);
    }
  };

  const updateTitle = async () => {
    setTitleUpdating(true);
    const result = await Retriver.do(
      NewUpdatePresetRequest(preset.id, {
        title: title.current,
      })
    );
    const updated = result?.updatePresetResponse?.preset;
    if (updated) {
      setPreset(updated);
      props.onUpdate?.(updated); // title 이 변경되었을 경우 상위 (list view) 에 반영 필요
    } else {
      title.current = oldTitle;
    }
    setTitleUpdating(false);
  };

  const updateDescription = async () => {
    setDescriptionUpdating(true);
    const result = await Retriver.do(
      NewUpdatePresetRequest(preset.id, {
        description: description.current,
      })
    );
    if (result?.updatePresetResponse?.preset) {
      setPreset(result?.updatePresetResponse.preset);
    } else {
      description.current = oldDescription;
    }
    setDescriptionUpdating(false);
  };

  const MetadataView = (props: { preset: Preset }) => {
    const meta = props.preset.metadata;
    return (
      <Descriptions column={2}>
        <Descriptions.Item label="ID">
          <IdPopover id={props.preset.id} content={RawJson(props.preset)} />{' '}
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {descriptionUpdating ? (
            <Spin size="small" />
          ) : (
            <Typography.Paragraph
              style={{ marginBottom: 0 }}
              editable={{
                maxLength: 55,
                onStart: () => {
                  oldDescription = description.current;
                },
                onChange: (v) => {
                  description.current = v;
                },
                onEnd: updateDescription,
              }}
            >
              {description.current}
            </Typography.Paragraph>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Sample Color">
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: `#${meta?.sampleColor}`,
                borderRadius: '50%',
                marginRight: '0.5rem',
              }}
            />
            {`#${meta?.sampleColor}`}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Author">
          <Typography.Paragraph code>
            {`${meta?.author?.id}`}
          </Typography.Paragraph>
          {`(${meta?.author?.username} / ${meta?.author?.fullname})`}
        </Descriptions.Item>
        <Descriptions.Item label="Tags">
          <TagEntries
            value={meta?.tags}
            layout={'horizontal'}
            onChange={updateTags}
          />
        </Descriptions.Item>
      </Descriptions>
    );
  };

  useEffect(() => {
    async function adjustBoxHeight() {
      if (preset.metadata?.originUrl) {
        const imgSize = await getMediaResolution(preset.metadata?.originUrl);
        if (imgSize.height > imgSize.width) {
          const height = (imgSize.height * imgWidth) / imgSize.width;
          const box = document.getElementById(boxId);
          if (box) {
            box.style.height = `${height + 40}px`;
          }
        }
      }
    }
    adjustBoxHeight();
  });

  const renderTitlebar = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',
          marginBottom: '0.6rem',
          width: '100%',
        }}
      >
        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
          {titleUpdating ? (
            <Spin size="small" />
          ) : (
            <Typography.Paragraph
              editable={{
                maxLength: 7,
                onStart: () => {
                  oldTitle = title.current;
                },
                onChange: (v) => {
                  title.current = v;
                },
                onEnd: updateTitle,
              }}
            >
              {title.current}
            </Typography.Paragraph>
          )}
        </div>
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              newPreviewFilename.current = ulid();
              newOriginFilename.current = ulid();
              setModalOpen(true);
            }}
          >
            <FileImageOutlined />
          </Button>
          <Button
            style={{ marginLeft: '0.5rem' }}
            danger={!preset.metadata?.hidden}
            onClick={toggleHidden}
            loading={toggleHiddenLoading}
          >
            {preset.metadata?.hidden ? (
              <PlusCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            )}
          </Button>
          <Popconfirm
            title="Are you sure to DELETE？"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={deletePreset}
          >
            <Button
              style={{ marginLeft: '0.5rem' }}
              type="primary"
              danger
              loading={deletePresetLoading}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <>
        <div id={boxId} style={{ display: 'flex' }}>
          <ImageComparisonView
            id={preset.id}
            width={imgWidth}
            rightImageUrl={preset.metadata?.originUrl ?? FallbackImage}
            rightImageLabel="Origin"
            leftImageUrl={preset.metadata?.previewUrl ?? FallbackImage}
            leftImageLabel="Preview"
          />
          <div
            style={{
              position: 'relative',
              left: '340px',
              paddingRight: '340px',
            }}
          >
            <MetadataView preset={preset} />
            <CameraConfigurationView conf={preset.cameraConfig} />
          </div>
        </div>
        <div>
          <ImageProcessingView processing={preset.imageProcessing} />
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <Modal
        open={modalOpen}
        title="Change Images"
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              modalForm.resetFields();
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              modalForm.validateFields().then((values) => {
                handleModalFormSubmit(values);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="horizontal">
          <Form.Item
            name="preview"
            label="Preview"
            extra={acceptTypesInfo(PresetImageAcceptTypes)}
          >
            <FileUploader
              storage="retriver"
              accept={PresetImageAcceptTypes}
              filename={`presets/${props.preset.id}/${newPreviewFilename.current}`}
              container={''}
            />
          </Form.Item>
          <Form.Item
            name="origin"
            label="Origin"
            extra={acceptTypesInfo(PresetImageAcceptTypes)}
          >
            <FileUploader
              storage="retriver"
              accept={PresetImageAcceptTypes}
              filename={`presets/${props.preset.id}/${newOriginFilename.current}`}
              container={''}
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className={`${viewStyle.viewbox}`} style={{ margin: '1rem 0' }}>
        {renderTitlebar()}
        {preset.metadata?.hidden ? (
          <Watermark content={'❌ HIDDEN ❌'}>{renderDetails()}</Watermark>
        ) : (
          renderDetails()
        )}
      </div>
    </React.Fragment>
  );
}

function CameraConfigurationView(props: {
  conf: CameraConfiguration | undefined;
}) {
  const conf = props.conf;
  const flashEnumType = proto3.getEnumType(CameraConfiguration_Flash);
  const deviceEnumType = proto3.getEnumType(CameraConfiguration_DeviceType);
  const posEnumType = proto3.getEnumType(CameraConfiguration_Position);
  const ratioEnumType = proto3.getEnumType(CameraConfiguration_CameraRatio);

  return (
    <div style={{ marginTop: '-1rem' }}>
      <Divider orientation="left">
        <CameraOutlined style={{ marginRight: '0.4rem' }} />
        Camera Configuration
      </Divider>
      <Descriptions column={3}>
        <Descriptions.Item label="WB">
          {`Temp ${conf?.whiteBalance?.temperature ?? '-'}`}
          <br />
          {`Tint ${conf?.whiteBalance?.tint ?? '-'}`}
        </Descriptions.Item>
        <Descriptions.Item label="EXP">
          {`Bias ${conf?.exposure?.bias?.value ?? '-'}`}
          <br />
          {`ISO ${conf?.exposure?.iso ?? '-'}`}
          <br />
          {`S/S ${conf?.exposure?.shutterSpeed.toFixed(5) ?? '-'}`}
        </Descriptions.Item>
        <Descriptions.Item label="Flash">
          {`${conf?.flash ?? 0} - ${
            flashEnumType.findNumber(conf?.flash ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Device Type">
          {`${conf?.deviceType ?? 0} - ${
            deviceEnumType.findNumber(conf?.deviceType ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Position">
          {`${conf?.position ?? 0} - ${
            posEnumType.findNumber(conf?.position ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Format">{conf?.format}</Descriptions.Item>
        <Descriptions.Item label="Camera Ratio">
          {`${conf?.cameraRatio ?? 0} - ${
            ratioEnumType.findNumber(conf?.cameraRatio ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Zoom">{`${conf?.zoom}`}</Descriptions.Item>
      </Descriptions>
    </div>
  );
}
