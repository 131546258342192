import { useFetchPresets } from 'hooks/useFetchPresets';
import { useRef } from 'react';
import viewStyle from 'styles/view.module.css';
import { Preset } from 'types/proto/retriver-struct_pb';
import { Spin } from 'antd';
import { PresetTinyView } from './PresetTinyView';

export function TaggedPresetsView(props: { tag: string }) {
  const lastItemRef = useRef(null);
  const [presets, , hasMore] = useFetchPresets({
    query: { rawTag: props.tag },
    lastItemRef: lastItemRef,
  });

  return (
    <div
      className={`${viewStyle.viewbox} ${viewStyle.wrap}`}
      style={{ paddingTop: '3rem' }}
    >
      {presets.map((p: Preset) => {
        return <PresetTinyView key={p.id} preset={p} />;
      })}
      {hasMore && (
        <div
          key="presetListLastItemRef"
          ref={lastItemRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1rem',
            minHeight: '100px',
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}
